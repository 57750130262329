import { Fragment } from "react";
import { useSelector } from "react-redux";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectList({ options, selected, onChange }) {
  const { current_Language } = useSelector((store) => store.settingsReducer);

  return (
    <Listbox value={selected} onChange={onChange}>
      <ListboxButton className="relative w-[120px] bg-primary cursor-default rounded-lg py-2 pl-3 pr-10 text-left  shadow-sm  focus:outline-none hover:cursor-pointer">
        <span className="block truncate capitalize text-white">{selected}</span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </span>{" "}
      </ListboxButton>
      <ListboxOptions
        anchor="bottom"
        className="absolute z-[11] max-h-60 w-[120px] mt-1 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        {options.map((person) => (
          <ListboxOption
            key={person.id}
            value={person?.attributes?.sortBy}
            className={({ active }) =>
              classNames(
                active ? "bg-[#EBF3FB] text-black" : "text-gray-900",
                "relative cursor-default select-none py-2 pl-3 pr-9"
              )
            }
          >
            {({ selected, active }) => (
              <>
                <span
                  className={classNames(
                    selected
                      ? "font-semibold capitalize"
                      : "font-normal capitalize hover:cursor-pointer",
                    "block truncate capitalize"
                  )}
                >
                  {current_Language === "de"
                    ? person?.id === 1
                      ? "Name"
                      : person?.id === 3
                      ? "Preis"
                      : "am nächsten"
                    : person?.attributes?.sortBy}
                </span>
                {selected ? (
                  <span
                    className={classNames(
                      active ? "text-black " : "",
                      "absolute inset-y-0 right-0 flex items-center pr-4"
                    )}
                  >
                    <CheckIcon className="h-5 w-5 " aria-hidden="true" />
                  </span>
                ) : null}
              </>
            )}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
}
